.popup-holder{
  width:100vw;
  height:100vh;
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color:rgba(0,0,0,.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:100;
  padding:14px;
}
.popup-nav{
  margin:unset;
  margin-bottom:25px;
  padding:13px 14px;
  background-color:#F5F7FC;
  border:1px solid #E8E8E8;
  justify-content: flex-start;
  width:fit-content;
  &.desktop-nav{
    width:100%;
    margin-top:30px;
  }
  span{
    min-width:154px;
    height:50px;
    &.active{
      color:#FFF;
    }
  }
  @include tablet-down{
    background-color: unset;
    span{
      border:none;
    }
  }
  @include mobile-down{
    margin:0 auto 4px;
    width:100%;
    justify-content: space-evenly;
    span{
      min-width:unset;
      width:calc(50% - 5px);
    }
  }
}
.popup-body{
  position: relative;
  background-color:#FFF;
  display:flex;
  flex-direction: column;
  width:100%;
  max-width:1234px;
  max-height:90vh;
  border-radius: 17px;
  box-shadow: 0px 0px 24px 0px rgba(0,0,0,.3);
  padding:35px 45px 35px 60px;
  &.tabbed{
    height:90vh;
  }
  &.register-accounts{
    max-width:606px;
  }
  &.evoshare{
    padding:35px 0;
    max-width:866px;
  }
  &.evoshare-result{
    padding:53px 0;
    max-width:560px;
  }
  @include tablet-down{
    padding:50px 34px;
  }
  @include mobile-down{
    max-height:100%;
    padding:16px;
    &.tabbed{
      padding:66px 24px 24px;
      max-height:calc(100vh - 40px);
      height:calc(100vh - 40px);
    }
  }
  @include mobile-tiny-down{
    padding:12px;
    &.tabbed{
      padding:66px 12px 12px;
    }
  }
  .popup-close{
    position: absolute;
    top:0;
    right:0;
    width:88px;
    height:43px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 17px;
    border-bottom-left-radius: 8px;
    font-size:12px;
    background-color: #F4F4F4;
    color:#000;
    z-index: 10;
    &:hover{
      cursor: pointer;
    }
    img{
      $size:9px;
      width:$size;
      min-width: $size;
      height: $size;
      min-height: $size;
      margin-right:7px;
    }
  }
  .popup-content{
    overflow: auto;
    &.payoff-calculator{
      color:#000;
    }
    &>.title{
      font-size:24px;
      font-weight:900;
      margin-bottom:64px;
      margin-top:30px;
      @include mobile-down{
        margin-bottom:42px;
        width:100%;
        text-align: center;
      }
    }
  }
}

.faq-title{
  width:100%;
  text-align: center;
  margin:50px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:30px;
  font-weight:900;
  img{
    margin-right:15px;
    width:37px;
    max-width: 37px;
    height:40px;
    max-height: 40px;
    object-fit: contain;
  }
  @include mobile-down{
    flex-direction: column;
    margin-bottom:25px;
    font-size:22px;
    img{
      margin-bottom:10px;
    }
  }
}

.faqs{
  padding:64px;
  border-radius: 17px;
  border:1px solid $blue-gray;
  @include tablet-down{
    padding:68px 20px;
  }
  @include mobile-down{
    padding:34px 20px;
  }
  h3{
    font-size: 20px;
    border-bottom:1px solid $blue-gray;
    width:100%;
    padding-bottom:12px;
    margin-bottom:25px;
    &:not(:first-child){
      margin-top:60px;
    }
  }
  p{
    margin-bottom:24px;
    font-size: 16px;
    line-height:21px;
    opacity: .5;
  }
}

.thrive-description{
  @include mobile-down{
    padding:0 20px;
  }
  h2{
    width:100%;
    text-align: center;
    margin:50px auto 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:20px;
    font-weight:700;
    border-bottom:1px solid $blue-gray;
    padding-bottom:27px;
    img{
      margin-right:15px;
      width:37px;
      max-width: 37px;
      height:40px;
      max-height: 40px;
      object-fit: contain;
    }
    @include mobile-down{
      flex-direction: column;
      margin-bottom:25px;
      font-size:22px;
      img{
        margin-bottom:10px;
      }
    }
  }
  p{
    font-size:15px;
    line-height:24px;
    margin-bottom:24px;
    opacity: .5;
  }
}

.evoshare-register-popup{
  position: relative;
  text-align: center;
  &>h3{
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0px;
  }
  &>img{
    width:170px;
    max-width:100%;
    margin-bottom:20px;
    object-fit: contain;
  }
  .description{
    width:100%;
    padding:30px 30px 40px;
    margin-bottom:40px;
    border-top:1px solid rgb(228,233,245);
    border-bottom:1px solid rgb(228,233,245);
    color:#000;
    font-size:14px;
    line-height: 16px;
    text-align: center;
    p{
      width:100%;
      max-width:680px;
      margin:0 auto;
    }
  }
  label,.label{
    text-align: left;
    display: block;
    width:298px;
    max-width:100%;
    font-size: 14px;
    line-height:14px;
    margin:0 auto 20px;
    input{
      margin-top:10px;
    }
  }
  &>form{
    &>button{
      margin-bottom:30px;
    }
  }
}

.evoshare-result-popup{
  min-height:40vh;
  text-align: center;
  @include mobile-down{
    min-height: 60vh;
  }
  .title{
    text-align: center;
    border-bottom:1px solid rgb(228,233,245);
    padding:0 35px 16px;
    margin:0 0 58px !important;
    img{
      max-width:170px;
      height: auto;
      object-fit: contain;
    }
  }
  .body{
    padding:0 16px;
    &>img{
      max-width:100%;
      height: 46px;
      margin-bottom:32px;
      opacity:.4;
    }
  }
  p{
    margin-bottom:32px;
  }
  h2{
    font-size:30px;
    margin-bottom:20px;
  }
  a{
    margin-bottom:32px
  }
  .standard-link{
    display: block;
    margin-bottom:50px;
  }
  .buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top:40px;
    padding:40px 16px 0 16px;
    //top:90px;
    //margin-top:-90px;
    position: absolute;
    bottom:40px;
    //left:50%;
    //transform: translateX(-50%);
    width: 100%;
    //height:150px;
    border-top:1px solid #E4E9F5;
    @include mobile-small-down{
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    button{
      display: block;
      font-size: 18px;
      width:134px;
      height:50px;
      border-radius:10px;
      color:#FFF;
      position: relative;
      @include mobile-small-down{
        margin:0 auto 20px;
        width: 100%;
      }
      &:first-child{
        margin-right:10px;
        @include mobile-small-down{
          margin-right:auto;
          margin-bottom:20px;
        }
      }
      span{
        position: relative;
        z-index: 2;
      }
      &:hover{
        &::before{
          content: "";
          position: absolute;
          top:0;
          left:0;
          width:100%;
          height:100%;
          background-color: rgba(0,0,0,.2);
          border-radius:10px;
          z-index: 1;
        }
      }
    }
    .yes{
      background-color:$green;
    }
    .no{
      background-color:#F67281;
    }
  }
}

.large-form{

}
.save-row{
  position: relative;
}
.popup-form-error{
  position: absolute;
  top:60px;
  font-weight: 800;
  color:darken($red,10);
}
.bank-instructions{
  display: block !important;
  padding:0 24px;
  p {
    display: block;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }
}
.document-details{
  ul{
    text-align: left;
    padding:20px 30px;
    margin: 0 auto;
    width:fit-content;
    li{
      margin-bottom:10px;
    }
  }
}

.resubmit{
  button{
    background-color: #E5E5E5;
    border:1px solid #999;
    span{
      color:#000;
    }
  }
  p{
    font-size:14px;
    text-align: center;
    padding:12px 20px;
  }
}