header{
  box-shadow: 0px 4px 15px 0px rgba(0,0,0,0.2);
  padding-left:40px;
  z-index: 99;
  position: relative;

  &.simple{
    padding:0 !important;
  }
}
.header-logo{
  width:162px;
  height:48px;
  object-fit: contain;
  @include tablet-down{
    width:135px;
    height:40px;
  }
}
.header-holder{
  height:95px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height:95px;
}
.desktop-nav-holder{
  height:100%;
  display: flex;
  align-items: flex-end;
  @include med-tablet-down{
    display:none;
  }
  .account-button{
    height: 100%;
    padding:0 20px 0 25px;
    display: flex;
    align-items: center;
    border-left:1px solid rgba(112,112,112,.11);
    position: relative;
    .org-name-holder{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-left:12px;
      max-width:200px;
    }
    .org-name{
      display: inline-block;
      max-width:180px;
      line-height: 18px;
      font-size:16px;
      font-weight:500;
      opacity: 1;
    }
    .plan-name{
      font-size: 12px;
      font-weight:500;
      opacity: .8;
      margin-top:8px;
      display: block;
      white-space: nowrap;
    }
    .option-list{
      min-width:210px;
      max-width:calc(100vw - 10px);
      right:10px;
      left:unset;
      top:75px;
      border-radius: 7px;
      border:#E8E8E8 1px solid;
      box-shadow: 0 3px 6px #00000064;
      span,a{
        padding-left:24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        white-space: nowrap;
        &:not(:first-child){
          border-top:1px solid $light-gray;
        }
        img{
          //margin-left:60px;
          margin-top:3px;
          opacity: .5;
        }
        &:hover{
          img{
            opacity: 1;
          }
        }
      }
    }
    &>img{
      $size:34px;
      min-height: $size;
      height:$size;
      width:$size;
      min-width:$size;
      border-radius: 100%;
      border:1px solid $light-gray;
    }
    &>span{
      font-size: 34px;
      opacity: .15;
    }
    &:hover{
      span{
        opacity: 1;
      }
    }
  }
}
.main-menu{
  height:100%;
  display: flex;
  align-items: flex-end;
  a,span{
    box-sizing: content-box;
    display: inline-block;
    padding:0 12px 34px;
    margin: 0 8px;
    border-bottom:5px solid rgba(0,0,0,0);
    font-size:15px;
    opacity: .5;
    color:#000;
    &:hover,&.active{
      opacity: 1;
      font-weight: 500;
      border-bottom:5px solid #F67281;
    }
  }
}
.mobile-nav-holder{
  height:100%;
}
.mobile-menu-button{
  height: 100%;
  padding:0 20px 0 25px;
  display: flex;
  align-items: center;
  border-left:1px solid rgba(112,112,112,.11);
  &>img{
    $size:34px;
    min-height: $size;
    height:$size;
    width:$size;
    min-width:$size;
  }
}

.mobile-main-menu-holder{
  position: fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background-color:rgb(93,95,183);
  display: flex;
  flex-direction: column;
  z-index:99;
  max-height:0;
  transition: max-height .5s;
  overflow: hidden;
  &.open{
    max-height:100vh;
    transition: max-height .5s;
  }
  .menu-holder{
    height:100%;
    max-height:100%;
    overflow: scroll;
  }
  .main-menu{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:100%;
    width:100%;

    a,span{
      color:#FFF;
      padding:0 3px 3px;
      font-size:22px;
      width:fit-content !important;
      margin-bottom:20px;
      img{
        margin-left:20px;
      }
    }
  }
}
.mobile-menu-header{
  background-color:rgb(74,76,146);
  height:95px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:24px 0 24px 20px;
  .mobile-menu-button{
    height:95px;
    border-left:1px solid rgb(69,104,177);
  }
}
.mobile-menu-footer{
  .footer {
    border-top:1px solid rgba(255,255,255,.08);
    background-color: #5D5FB7;
    span,a{
      color:#FFF;
    }
    span{
      opacity: .5;
    }
    a{
      border-bottom:2px solid #FFF
    }
  }
}

.upload-checkbox{
  display: flex;
  align-items: center;
  margin-top: 8px;
  .checkbox{
    margin-right: 7px;
  }
}
